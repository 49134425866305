
body {
    background-color: #121212;
}

/* @media only screen and (max-width: 768px) {
    .some-name {
        display: flex;
        flex-direction: column;
        align-content: center;
      
    }
} */

.settings-page {
    width: 100%;
    height: 100vh;
}

.settings-content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.settings-header {
    margin-top: 0px;
    color: #fff;
    margin-right: 18px;
}

