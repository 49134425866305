
.bottom-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #252525;
  padding: 0.7rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

  
  .bottom-navbar nav {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .bottom-navbar .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6e758f;
    text-decoration: none;
    font-size: 0.5rem;
    justify-content: space-between;
  }

  .bottom-navbar .nav-link.active {
    color: #d1d7ea; /* Set the desired color for the active tab */
  }

  .bottom-navbar .nav-link:hover {
    color: #d1d7ea;
  }
  
  .nav-icon {
    font-size: 1.25rem;
    margin-bottom: 4px;
  }
  
  /* Display bottom navbar for mobile devices only */
  @media screen and (min-width: 768px) {
    .bottom-navbar {
      display: none;
      justify-content: space-around;
      align-items: center;
    }
  }