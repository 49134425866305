.default-layout-content {
    margin-left: 286px;
    transition: margin-left 0.3s ease;
  }
  
  .default-layout-content.collapsed {
    margin-left: 86px;
  }
  
  @media (max-width: 768px) {
    .default-layout-content:not(.collapsed) {
      margin-left: 0;
    }
  }
  
  .default-layout-content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
  }