.dropdownMenuButton {
  margin-top: 0px;
  max-width: 80px;
  max-height: 24px;
}

.dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    list-style-type: none;
    padding: 8px;
    width: 150px;
    position: absolute;
  }
  
  .dropdown-menu-item {
    color: #000;
    cursor: pointer;
  }

