
body {
    background-color: #121212;
    paddign-left: 16px;
    paddign-right: 16px;
}

/* @media only screen and (max-width: 768px) {
    .some-name {
        display: flex;
        flex-direction: column;
        align-content: center;
      
    }
} */

.home-content {
    /* display: flex;
    flex-direction: column; */
    /* position: fixed; */
    height: 48px;
    
    
}

.home-top-content {
    /* Change position to fixed */
    position: fixed;
    /* Set the width to 100% for full-width across the viewport */
    width: 100%;
    /* Set the background color to prevent the content below from showing through */
    background-color: #121212;
    /* Add some padding for better appearance */
    padding: 1rem;
    /* Set z-index to keep the top content above the saved jobs list */
    z-index: 1;
}

.saved-jobs {
    /* Add margin-top to move the saved jobs list below the top content */
    margin-top: 4rem;
    padding: 16px;
  }

.home-header {
    color: #FFF;
    margin-right: 18px;
}

/* .content-columns-home {
    display: flex;
    position: relative;
    height: 100%;
} */

.content-columns-home {
    /* Remove position property */
    height: 100%;
    overflow-y: auto;
  }

/* .left-column-home,
.right-column-home {
  flex: 1;
  height: 100%;
  overflow-y: auto; 
  padding: 1rem; 
} */

/* .left-column-home {
    flex-basis: 40%;
  } */

/* 
  .right-column-home {
    flex-basis: 60%;
  } */

/* .saved-jobs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
} */

.section-subheader {
    font-size: 24px;
    color: #FFF;
}