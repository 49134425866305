.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    background-color: #252525;
    padding: 24px;
    overflow-y: auto;
    color: #fff;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }
  
  .sidebar.collapsed {
    transform: translateX(-202px);
    transition: transform 0.2s ease-in-out;
  }
  
  .sidebar a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: #fff;
  }
  
  .sidebar a:hover {
    background-color: #555;
  }
  
  .sidebar-content {
    width: 100%;
  }

  .toggle-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-items: flex-end;
    /* width: 100%; */
  }

  .toggle-btn {
    display: flex;
    flex-direction: row;
    justify-items: flex-end;
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

    /* Display CollapsibleSidebar for non-mobile devices only */
    @media screen and (max-width: 767px) {
        .sidebar {
          display: none;
          /* justify-content: space-around;
          align-items: center; */
        }
      }