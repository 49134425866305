

.page-header{
    display: flex;
    flex-direction: left;
    margin-left: 16px;
    margin-top: 104px;
    font-size: 28px;
    color: #ccc;
}

.job-card {
    width: 100%;
    border-bottom: 2px solid #212121;
    padding-top: 16px;
    padding-bottom: 16px;
  }

.job-info {
    display: flex;
    cursor: pointer;
    flex-direction: row;
}
  
.company-logo-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    width: 88px;
    background-color: #212121;
    border-radius: 4px;
    margin-right: 8px;
}

.company-logo {
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.job-card-info {
    height: 88px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: space-between;
    justify-content: flex-start;
}

.company {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 400;
    color: #bbb;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 4px;
}

.job-title {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 0px;
    margin-top: 2px;
    margin-bottom: 8px;
}

.job-summary {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 400;
    color: #bbb;
    padding: 0px;
    margin-top: 0px;
}

.click-indicator-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 16px;
    padding-top: 8px;
}

.dropdown-box {
    display: none;
    background-color: transparent;
    border: none;
    height: 48px;
    width: 48px;
}

.dropdown-menu {
    display: none;
  }

  .dropdown-toggle {
    display: none;
    /* Styles for button */
  }

  .dropdown-toggle:focus + .dropdown-menu,
  .dropdown-toggle:hover + .dropdown-menu {
    display: none;
  }

.dropdown-menu-icon {
    display: none;
    color: #fff;
    height: 20px;
    width: 20px;

}

.chevron-right {
    display: none;
    height: 15px;
    width: 9px;
    color: #fff;
}

.job-source {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 400;
    color: #bbb;
    padding: 0px;
    margin-top: 4px;
    margin-bottom: 0px;
}

.card-action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
}

.action-icon-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.action-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 20px;
    width: 20px;
    margin-right: 20px;

}

.primary-action-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    background-color: #262626;
    border-radius: 24px;
    border: 3px solid #8dfece;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 0px;
    margin-bottom: 0px;    
}

.button-icon
{
    height: 28px;
    width: 28px;
    margin-right: 12px;
}

.button-text {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin: 0px;
}


  .job-url {
    width: 100%;
    /* max-width: 600px; Adjust this value to a suitable width */
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ccc;
  }
  
  .job-companyname {
    color: #ccc;
  }

  .job-text {
    width: 100%;
    max-width: 600px; /* Adjust this value to a suitable width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ccc;
  }
  
  .job {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    width: 300px;
    color:#ccc;
  }