.login-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh; 
    background-color: #fff;
}
  
@media only screen and (max-width: 768px) {
    .login-container {
        display: flex;
        flex-direction: column;
        align-content: center;
      
    }
}
  
.left-column {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('https://www.dropbox.com/s/px0la1chu5i8vui/hero-test-2.png?dl=1');
    background-size: cover;
    background-position: center;
}  

@media only screen and (max-width: 768px) {
    .left-column {
      display: none !important;
    }
}

.lc-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    /* align-items: space-between; */
}

.lc-top {
    display: flex;
    flex-direction: column;
}


.lc-cta {
    margin-left: 64px;
    margin-top: 64px;
    max-width: 40%;
    font-size: 48px;
    color: #1c6aad;
}

.lc-cta2 {
    margin-left: 64px;
    margin-top: 0px;
    max-width: 40%;
    font-size: 36px;
    color: #1c6aad;
}

.lc-bottom {
    display: flex;
    justify-content: flex-end;
}


.lc-logo {
    margin-left: 64px;
    margin-top: 64px;
    max-width: 50%;
    font-size: 48px;
    color: #fff;
  }
  
.right-column {
    display: flex;
    width: 40%;
    align-content: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-width: 20%;
    max-width: 40%; */
    /* padding: 2rem; */
}

@media only screen and (max-width: 768px) {
    .right-column {
        display: flex;
        width: 100%;
        align-content: stretch;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* min-width: 20%;
        max-width: 40%; */
        /* padding: 2rem; */
        /* margin-top: none; */
    }
}

.rc-container {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    align-items: center;
    width: 100%;
}

.signup-login-toggle {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
}

.login-header {
    color: #D1D7EA;
    margin-bottom: 48px;
    margin-right: 16px;
}

.login-header:hover {
    color: #303854;
    cursor: pointer;
}

.activeHeader {
    color: #303854; 
  }

.signup-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.login-button-google {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #D1D7EA;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #303854;
    cursor: pointer;
    transition: color 1s ease;
}

.login-button-google:hover {
    background-color: #D1D7EA;
}

.separator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "futura-pt";
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 48px;
    color: #D1D7EA;
}

.separator::before {
        content: '';
        height: 2px;
        background: #D1D7EA;
        flex: 1;
        margin: 0 24px 0 0;
}

.separator::after {
    content: '';
    height: 2px;
    background: #D1D7EA;
    flex: 1;
    margin: 0 0 0 24px;
}

input[type="email"],
input[type="password"] {
    width: stretch;   /* Remove width 100% */ 
    margin: 0 auto; /* Center in parent */
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #303854;
    padding: 16px;
    font-weight: bold;
}

.email-error.show {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 16px;
    color: red;
  }
  
  .email-error.hide {
    display: none; 
  }

  .email-invalid {
    border: 1px solid red;
  }

  .password-error.show {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 16px;
    color: red;
  }
  
  .password-error.hide {
    display: none; 
  }

  .password-invalid {
    border: 1px solid red;
  }

  .error-message {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 16px;
    color: red;
  }

.compliance-text {
    font-size: 10px;
    margin-bottom: 24px;
    color: #303854;
}

.login-button-email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #D1D7EA;
    border-radius: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: default;
}

.login-button-email-enabled {
    background-color: #303854;
    cursor: pointer;
}

.login-button-email-disabled {
    background-color: #D1D7EA;
    cursor: default;
}
  
button {
    width: 100%;
    /* padding: 0.5rem;
    margin-bottom: 1rem; */
    cursor: pointer;
}
  
.separator {
    text-align: center;
    width: 100%;
    /* margin-bottom: 1rem; */
}